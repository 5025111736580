import PropTypes from "prop-types";
import React, { useMemo } from "react";

import { useStaticQuery, graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";

const Img = ({ src, ...props }) => {
  const data = useStaticQuery(graphql`
    query {
      allFile(
        filter: {
          internal: {
            mediaType: {
              nin: ["image/svg+xml", "application/javascript"]
              regex: "images/"
            }
          }
        }
      ) {
        edges {
          node {
            relativePath
            childImageSharp {
              gatsbyImageData(
                layout: FLUID
                placeholder: DOMINANT_COLOR
                maxWidth: 1920
                formats: WEBP
                webpOptions: { quality: 100 }
              )
            }
          }
        }
      }
    }
  `);

  const match = useMemo(
    () => data.allFile.edges.find(({ node }) => src === node.relativePath),
    [data, src]
  );

  return (
    <GatsbyImage
      objectFit="cover"
      objectPosition="left"
      style={{ overflow: "auto", position: "static" }}
      image={
        match &&
        match.node &&
        match.node.childImageSharp &&
        match.node.childImageSharp.gatsbyImageData
      }
      {...props}
    />
  );
};

Img.propTypes = {
  src: PropTypes.string.isRequired,
  ...GatsbyImage.propTypes,
};

Img.defaultProps = {
  src: ``,
  ...GatsbyImage.defaultProps,
};

export default Img;
