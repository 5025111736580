import React from "react";
import PropTypes from "prop-types";
import { Checkbox } from "baseui/checkbox";
import { useTheme } from "../../contexts/theme";

import moon from "../../assets/images/moon.svg";
import sun from "../../assets/images/sun.svg";

const ThemeSwitch = ({ $style, color, ...props }) => {
  const { useDarkTheme, setUseDarkTheme } = useTheme();

  return (
    <Checkbox
      {...props}
      checked={useDarkTheme}
      onChange={e => setUseDarkTheme(e.target.checked)}
      checkmarkType="toggle_round"
      overrides={{
        Root: {
          style: {
            position: "absolute",
            top: "10px",
            right: "10px",
            zIndex: 5,
            ...$style,
          },
        },
        ToggleTrack: {
          style: ({ $theme }) => ({
            backgroundColor: "unset",
            border: `1px solid ${color || $theme.colors.backgroundLightAccent}`,
            height: "20px",
            padding: "1px",
            borderTopLeftRadius: "20px",
            borderTopRightRadius: "20px",
            borderBottomRightRadius: "20px",
            borderBottomLeftRadius: "20px",
            ":before": {
              content: "''",
              backgroundImage: `url(${useDarkTheme ? sun : moon})`,
              backgroundPosition: "center center",
              backgroundRepeat: "no-repeat",
              backgroundSize: "contain",
              textTransform: "uppercase",
              fontSize: "7px",
              fontWeight: "300",
              width: "8px",
              height: "8px",
              left: !useDarkTheme ? "38px" : "19px",
              position: "absolute",
              padding: "3px",
              opacity: "0.5",
              transitionProperty: "all",
              transitionDuration: "200ms",
              transitionTimingFunction: "ease-in-out",
            },
          }),
        },
        Toggle: {
          style: ({ $theme }) => ({
            backgroundColor: color || $theme.colors.backgroundLightAccent,
            boxShadow: "none",
          }),
        },
      }}
    />
  );
};

ThemeSwitch.propTypes = {
  $style: PropTypes.object,
  color: PropTypes.string,
};

export default ThemeSwitch;
