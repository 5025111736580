import React from "react";
import PropTypes from "prop-types";

import { ProgressBar as BaseProgressBar } from "baseui/progress-bar";
import { LabelSmall } from "baseui/typography";

const ProgressBar = ({ ...props }) => {
  return (
    <BaseProgressBar
      {...props}
      overrides={{
        Root: {
          props: {
            "data-csweb": "ui-progress-bar",
          },
          style: props.$style,
        },
        Bar: {
          style: {
            marginLeft: 0,
            marginRight: 0,
            height: "18px",
            borderTopLeftRadius: "18px",
            borderTopRightRadius: "18px",
            borderBottomLeftRadius: "18px",
            borderBottomRightRadius: "18px",
          },
        },
        BarProgress: {
          style: {
            height: "18px",
            borderTopLeftRadius: "18px",
            borderTopRightRadius: "18px",
            borderBottomLeftRadius: "18px",
            borderBottomRightRadius: "18px",
            background:
              "linear-gradient(90deg, rgba(60,0,220,1) 0%, rgba(0,220,230,1) 100%)",
          },
        },
        // eslint-disable-next-line
        Label: ({ $value, $successValue }) => (
          <LabelSmall
            $style={{
              textTransform: "uppercase",
              letterSpacing: "0.5px",
              fontWeight: 300,
              textAlign: "center",
              marginBottom: "10px",
            }}
          >
            {props.getProgressLabel($value, $successValue)}
          </LabelSmall>
        ),
        ...props.overrides,
      }}
    />
  );
};

ProgressBar.propTypes = {
  ...BaseProgressBar.propTypes,
  getProgressLabel: PropTypes.func,
};

ProgressBar.defaultProps = {
  ...BaseProgressBar.defaultProps,
};

export default ProgressBar;
