export const primitives = {
  primaryFontFamily: "'Gilroy', sans-serif",
};

export const overrides = {
  breakpoints: {
    small: 540,
    medium: 1020,
    large: 1350,
    mediaQueries: {
      small: "@media screen and (max-width: 540px)",
      medium: "@media screen and (max-width: 1020px)",
      large: "@media screen and (max-width: 1350px)",
    },
  },
  borders: {
    radius400: "18px",
  },
  typography: {
    DisplayLarge: {
      fontSize: "67.5px",
      lineHeight: "73.125px",
      fontWeight: "700",
    },
    DisplayMedium: {
      fontSize: "60px",
      lineHeight: "65px",
      fontWeight: "600",
    },
    DisplaySmall: {
      fontSize: "52.5px",
      lineHeight: "58px",
      fontWeight: "700",
    },
    DisplayXSmall: {
      fontSize: "34px",
      lineHeight: "37.5px",
      fontWeight: "700",
    },
    HeadingXXLarge: {
      fontSize: "34px",
      lineHeight: "37.5px",
      fontWeight: "700",
    },
    HeadingXLarge: {
      fontSize: "64.5px",
      lineHeight: "70px",
      fontWeight: "700",
    },
    HeadingLarge: {
      fontSize: "34px",
      lineHeight: "37.5px",
      fontWeight: "700",
    },
    HeadingMedium: {
      fontSize: "26.25px",
      lineHeight: "31px",
      fontWeight: "700",
    },
    LabelLarge: {
      fontSize: "22.5px",
      lineHeight: "26.25px",
      fontWeight: "600",
    },
    LabelMedium: {
      fontSize: "16.875px",
      lineHeight: "23.5px",
      fontWeight: "600",
    },
    LabelSmall: {
      fontSize: "11.25px",
      fontWeight: "700",
    },
    LabelXSmall: {
      fontSize: "10px",
      fontWeight: "700",
    },
    ParagraphLarge: {
      fontSize: "16.875px",
      lineHeight: "23.5px",
    },
    ParagraphMedium: {
      fontSize: "17.8px",
      lineHeight: "23.5px",
      fontWeight: "400",
    },
  },
};
