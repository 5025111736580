import PropTypes from "prop-types";
import React from "react";

import { Cell as BaseCell } from "baseui/layout-grid";
import { FlexGridItem } from "baseui/flex-grid";

const Cell = ({ flex, ...props }) => {
  if (flex)
    return (
      <FlexGridItem
        {...props}
        overrides={{
          Block: {
            props: {
              "data-csweb": "ui-cell",
            },
            style: {
              position: "relative",
            },
          },
        }}
      />
    );
  else
    return (
      <BaseCell
        {...props}
        overrides={{
          Cell: {
            props: {
              "data-csweb": "ui-cell",
            },
            style: {
              position: "relative",
            },
          },
        }}
      />
    );
};

Cell.propTypes = {
  flex: PropTypes.bool,
  ...BaseCell.propTypes,
  ...FlexGridItem.propTypes,
};

Cell.defaultProps = {
  flex: false,
  ...BaseCell.defaultProps,
  ...FlexGridItem.defaultProps,
};

export default Cell;
