import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { navigate } from "gatsby";

import { Grid, Cell, Button } from "../ui";
import { ContentObject } from "../content";
import { ThemeSwitch } from "../theme-switch";

import { useStyletron } from "baseui";
import {
  HeaderNavigation,
  HeaderNavigationList,
  HeaderNavigationItem,
  HeaderNavigationBrand,
  HeaderNavigationLangMenu,
  HeaderNavigationButton,
} from "./header-navigation";

import expandMenuPrimary from "../../assets/images/expand-menu.svg";
import shrinkMenuPrimary from "../../assets/images/shrink-menu-primary.svg";

import expandMenuWhite from "../../assets/images/expand-menu-white.svg";
import shrinkMenuWhite from "../../assets/images/shrink-menu.svg";

const Header = ({ color, size, position, $style }) => {
  const [css, theme] = useStyletron();
  const [isSticked, setIsSticked] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 400) setIsSticked(true);
      else setIsSticked(false);
    });
    window.addEventListener("resize", () => {
      if (window.innerWidth > theme.breakpoints.medium) setIsExpanded(false);
    });
  }, []);

  if (size === "compact")
    return (
      <header
        data-csweb="header"
        className={css({ zIndex: "4", position: "relative", ...$style })}
      >
        {!isExpanded && !isSticked && (
          <div
            className={css({
              [theme.breakpoints.mediaQueries.medium]: { display: "none" },
            })}
          >
            <ThemeSwitch {...(color && { color: color })} />
          </div>
        )}
        <Grid>
          <Cell span={[12, 12, 12, 10]} skip={[0, 0, 0, 1]}>
            <div className={css({ position: "relative" })}>
              <HeaderNavigation
                className={css({
                  position,
                  width: "100%",
                  justifyContent: "space-between",
                })}
              >
                <HeaderNavigationList showOnMobile $align="flex-start">
                  <HeaderNavigationBrand color={color} />
                </HeaderNavigationList>
                <HeaderNavigationList showOnMobile $align="flex-end">
                  {ContentObject.Header.buttons.compact.map((item, index) => (
                    <HeaderNavigationButton key={index}>
                      <Button
                        className={css({
                          [theme.breakpoints.mediaQueries.small]: {
                            display: "none",
                          },
                        })}
                        kind={item.kind}
                        color={color}
                        label={item.label}
                        onClick={() => {
                          if (typeof window !== "undefined" && window)
                            window.history.go(-1);
                          else navigate(item.to);
                        }}
                      >
                        {item.title}
                      </Button>
                      <Button
                        className={css({
                          display: "none !important",
                          [theme.breakpoints.mediaQueries.small]: {
                            display: "block !important",
                            width: "10ch",
                            minWidth: "unset",
                          },
                        })}
                        kind={item.kind}
                        color={color}
                        onClick={() => {
                          if (typeof window !== "undefined" && window)
                            window.history.go(-1);
                          else navigate(item.to);
                        }}
                      >
                        {item.title}
                      </Button>
                    </HeaderNavigationButton>
                  ))}
                </HeaderNavigationList>
              </HeaderNavigation>
            </div>
          </Cell>
        </Grid>
      </header>
    );
  else
    return (
      <header
        data-csweb="header"
        className={css({
          zIndex: "24",
          position: isSticked ? "fixed" : "relative",
          ...$style,
          ...(isSticked &&
            !isExpanded && {
              backgroundColor: theme.colors.backgroundSecondary,
              height: "70px",
              left: "0px",
              right: "0px",
              top: "0px",
              animationIterationCount: "1",
              animationDelay: "0ms",
              animationDuration: "200ms",
              animationFillMode: "both",
              animationName: {
                from: {
                  transform: "translateY(-200px)",
                },
                to: {
                  transform: "translateY(0px)",
                },
              },
            }),
          ...(isExpanded && {
            position: "fixed",
            left: "0px",
            top: "0px",
            right: "0px",
            bottom: "0px",
            backgroundColor: color
              ? theme.colors.primary
              : theme.colors.backgroundSecondary,
          }),
        })}
      >
        {!isExpanded && !isSticked && (
          <div
            className={css({
              [theme.breakpoints.mediaQueries.medium]: { display: "none" },
            })}
          >
            <ThemeSwitch {...(color && { color: color })} />
          </div>
        )}
        <Grid
          $style={{
            height: "100%",
            position: "relative",
          }}
        >
          <Cell span={[12, 12, 10]} skip={[0, 0, 1]}>
            <div className={css({ position: "relative", height: "100%" })}>
              <HeaderNavigation
                $style={{
                  position,
                  width: "100%",
                  paddingTop:
                    isSticked && !isExpanded ? "15px !important" : "60px",
                  paddingBottom:
                    isSticked && !isExpanded ? "15px !important" : "60px",
                  flexWrap: isExpanded ? "wrap" : "no-wrap",
                  boxSizing: "border-box",
                  ...(isExpanded && {
                    height: "100%",
                    paddingTop: `30px !important`,
                    paddingBottom: `30px !important`,
                  }),
                }}
              >
                <HeaderNavigationList
                  showOnMobile
                  $align="flex-start"
                  $as="div"
                >
                  <HeaderNavigationBrand
                    color={color}
                    shrink={isSticked && !isExpanded}
                  />
                </HeaderNavigationList>
                <HeaderNavigationList
                  $align={isSticked && !isExpanded ? "flex-end" : "center"}
                  $style={{
                    marginLeft:
                      isSticked && !isExpanded ? "auto !important" : "unset",
                    ...(isExpanded && {
                      height: "80%",
                      display: "flex !important",
                      order: "2",
                      width: "100%",
                      flexBasis: "auto",
                      textAlign: "center",
                      flexDirection: "column",
                    }),
                  }}
                >
                  {ContentObject.Header.navigation.map((item, index) => (
                    <HeaderNavigationItem
                      key={index}
                      color={isSticked && !isExpanded ? "" : color}
                      to={item.to}
                      $style={{
                        fontWeight: isSticked && !isExpanded ? "400" : "600",
                        ...(isExpanded && {
                          marginLeft: "0px",
                          display: "inline-block",
                          marginTop: theme.sizing.scale200,
                          marginBottom: theme.sizing.scale200,
                        }),
                      }}
                    >
                      {item.title}
                    </HeaderNavigationItem>
                  ))}
                  {isExpanded &&
                    ContentObject.Header.buttons.standard.map((item, index) => (
                      <HeaderNavigationItem
                        key={index}
                        color={isSticked && !isExpanded ? "" : color}
                        to={item.to}
                        $style={{
                          fontWeight: isSticked && !isExpanded ? "400" : "600",
                          ...(isExpanded && {
                            marginLeft: "0px",
                            display: "inline-block",
                            marginTop: theme.sizing.scale200,
                            marginBottom: theme.sizing.scale200,
                          }),
                        }}
                      >
                        {item.title}
                      </HeaderNavigationItem>
                    ))}
                </HeaderNavigationList>
                <HeaderNavigationList $align="flex-end">
                  {ContentObject.Header.buttons.standard.map((item, index) => (
                    <HeaderNavigationButton key={index}>
                      <Button
                        kind={item.kind}
                        color={isSticked && !isExpanded ? "" : color}
                        label={item.label}
                        onClick={() => navigate(item.to)}
                      >
                        {item.title}
                      </Button>
                    </HeaderNavigationButton>
                  ))}

                  {!isSticked && !isExpanded && false && (
                    <HeaderNavigationButton>
                      <HeaderNavigationLangMenu color={color} />
                    </HeaderNavigationButton>
                  )}
                </HeaderNavigationList>
                <HeaderNavigationList
                  $as="div"
                  $align="flex-end"
                  $style={{
                    display: "none",
                    marginLeft: "auto",
                    alignSelf: "baseline",
                    minHeight: "40px",
                    [theme.breakpoints.mediaQueries.medium]: {
                      display: "block",
                      paddingLeft: "0px",
                      paddingRight: "0px",
                    },
                  }}
                  onClick={() => setIsExpanded(!isExpanded)}
                >
                  <div
                    className={css({
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      height: isSticked && !isExpanded ? "40px" : "26px",
                    })}
                  >
                    <img
                      alt="Show/Hide menu"
                      src={
                        isExpanded
                          ? color
                            ? shrinkMenuWhite
                            : shrinkMenuPrimary
                          : color && !isSticked
                          ? expandMenuWhite
                          : expandMenuPrimary
                      }
                      className={css({
                        width: "20px",
                      })}
                    />
                  </div>
                </HeaderNavigationList>
                {isExpanded && (
                  <ThemeSwitch
                    $style={{
                      position: "relative",
                      order: "3",
                      marginLeft: "auto",
                      marginRight: "auto",
                    }}
                    {...(color && { color: color })}
                  />
                )}
              </HeaderNavigation>
            </div>
          </Cell>
        </Grid>
      </header>
    );
};

Header.propTypes = {
  color: PropTypes.string,
  position: PropTypes.string,
  size: PropTypes.string,
  $style: PropTypes.object,
};

Header.defaultProps = {
  color: ``,
  position: `relative`,
  size: ``,
  $style: {},
};

export default Header;
