import PropTypes from "prop-types";
import React from "react";

import { useStyletron } from "baseui";

import Content from "../../content";

import shine from "../../assets/images/shine.svg";

export const ContentObject = Content;

export const ContentItem = ({ scope, accessor, $style, ...props }) => {
  const [css] = useStyletron();

  return (
    <span
      data-csweb="content-item"
      className={css($style)}
      dangerouslySetInnerHTML={{
        __html: accessor.split(".").reduce((o, i) => o[i], Content[scope]),
      }}
      {...props}
    />
  );
};

ContentItem.propTypes = {
  scope: PropTypes.string.isRequired,
  accessor: PropTypes.string.isRequired,
  $style: PropTypes.object,
};

ContentItem.defaultProps = {
  scope: ``,
  accessor: ``,
  $style: {},
};

export const FormattedContentItem = ({ scope, accessor, $style, ...props }) => {
  const [css, theme] = useStyletron();

  return (
    <span
      data-csweb="content-item formatted"
      className={css($style)}
      dangerouslySetInnerHTML={{
        __html: accessor
          .split(".")
          .reduce((o, i) => o[i], Content[scope])
          .replace(/\*{2}(.+)\*{2}/gim, `<strong>$1</strong>`)
          .replace(
            /\*{1}(.+)\*{1}/gim,
            `<strong class=${css({
              fontWeight: "600",
            })}>$1</strong>`
          )
          .replace(
            /\/{2}(.+)\/{2}/gim,
            `<strong class=${css({
              color: theme.colors.primary,
            })}>$1</strong>`
          )
          .replace(
            /\/{1}(.+)\/{1}/gim,
            `<span class=${css({
              color: theme.colors.primary,
            })}>$1</span>`
          )
          .replace(
            /\{\{\{\{{1}([\w\s\\-]+)\}\}\}\}{1}/gim,
            `<span class="${css({
              borderColor: theme.colors.primary,
              backgroundColor: theme.colors.primary,
              borderWidth: "2px",
              borderStyle: "solid",
              borderRadius: "5px",
              display: "inline-block",
              marginRight: "8px",
              marginBottom: "8px",
              padding: "2px 8px",
              fontSize: "15px",
              fontWeight: 500,
              color: "white",
            })}">$1</span>`
          )
          .replace(
            /\{\{\{{1}([\w\s\\-]+)\}\}\}{1}/gim,
            `<span class="${css({
              borderColor: theme.colors.primary,
              borderWidth: "2px",
              borderStyle: "solid",
              borderRadius: "5px",
              display: "inline-block",
              marginRight: "8px",
              marginBottom: "8px",
              padding: "2px 8px",
              fontSize: "15px",
              fontWeight: 500,
            })}">$1</span>`
          )
          .replace(
            /\[n\]/gim,
            `<br class="${css({
              display: "none",
              [theme.breakpoints.mediaQueries.small]: {
                display: "block",
              },
            })}" />`
          )
          .replace(/\+/gim, `<img alt="Shine" src="${shine}" height="12px"/>`)
          .replace(/(?:\r\n|\r|\n)/g, "<br />"),
      }}
      {...props}
    />
  );
};

FormattedContentItem.propTypes = {
  scope: PropTypes.string.isRequired,
  accessor: PropTypes.string.isRequired,
  $style: PropTypes.object,
};

FormattedContentItem.defaultProps = {
  scope: ``,
  accessor: ``,
  $style: {},
};
