import PropTypes from "prop-types";
import React from "react";

import { Button as BaseButton } from "baseui/button";
import { useStyletron } from "baseui";

import { useTheme } from "../../contexts/theme";

const Button = ({
  label,
  kind,
  color,
  direction,
  radius,
  children,
  isControl,
  $style,
  ...props
}) => {
  const [css, theme] = useStyletron();
  const { useDarkTheme } = useTheme();

  if (kind === "secondary") {
    return (
      <BaseButton
        data-csweb="ui-button"
        overrides={{
          BaseButton: {
            style: {
              whiteSpace: "nowrap",
              color: color || theme.colors.buttonSecondaryText,
              borderTopLeftRadius: theme.borders.radius400,
              borderTopRightRadius: theme.borders.radius400,
              borderBottomRightRadius: theme.borders.radius400,
              borderBottomLeftRadius: theme.borders.radius400,
              fontWeight: "600",
              fontSize: "14px",
              paddingTop: theme.sizing.scale200,
              paddingBottom: theme.sizing.scale200,
              borderTopStyle: "solid",
              borderRightStyle: "solid",
              borderBottomStyle: "solid",
              borderLeftStyle: "solid",
              borderTopWidth: "2px",
              borderRightWidth: "2px",
              borderBottomWidth: "2px",
              borderLeftWidth: "2px",
              boxSizing: "border-box",
              borderTopColor: "transparent",
              borderRightColor: "transparent",
              borderBottomColor: "transparent",
              borderLeftColor: "transparent",
              transitionProperty: "all",
              transitionDuration: "200ms",
              transitionTimingFunction: "ease-in-out",
              minWidth: `${label.length < 16 ? 16 : label.length + 1}ch`,
              overflow: "hidden",
              maskImage: "-webkit-radial-gradient(white, black)",
              ":hover": {
                backgroundColor: "transparent",
                borderTopWidth: "2px",
                borderRightWidth: "2px",
                borderBottomWidth: "2px",
                borderLeftWidth: "2px",
                borderTopStyle: "solid",
                borderRightStyle: "solid",
                borderBottomStyle: "solid",
                borderLeftStyle: "solid",
                borderTopColor: color || theme.colors.primary,
                borderRightColor: color || theme.colors.primary,
                borderBottomColor: color || theme.colors.primary,
                borderLeftColor: color || theme.colors.primary,
              },
              ":hover span": {
                transform: "translateY(30px)",
              },
              ":hover span::after": {
                transform: "translateY(10px)",
              },
              ":hover em": {
                opacity: "1",
                transform: "scale(1)",
              },
            },
          },
        }}
        kind={"tertiary"}
        {...props}
      >
        <span
          className={css({
            position: "relative",
            transitionProperty: "all",
            transitionDuration: "200ms",
            transitionDelay: "100ms",
            transitionTimingFunction: "ease-in-out",
            "::after": {
              content: "''",
              position: "absolute",
              left: "0",
              right: "0",
              bottom: "-2px",
              height: "2px",
              transitionProperty: "all",
              transitionDuration: "200ms",
              transitionTimingFunction: "ease-in-out",
              background: color || theme.colors.backgroundLightAccent,
            },
          })}
        >
          {children}
        </span>

        <em
          className={css({
            position: "absolute",
            fontStyle: "normal",
            transitionProperty: "all",
            transitionDuration: "100ms",
            transitionDelay: "200ms",
            transitionTimingFunction: "ease-in-out",
            opacity: "0",
            transform: "scale(0)",
          })}
        >
          {label}
        </em>
      </BaseButton>
    );
  } else if (kind === "circle") {
    return (
      <BaseButton
        {...props}
        data-csweb="ui-button"
        overrides={{
          BaseButton: {
            style: {
              whiteSpace: "nowrap",
              color: theme.colors.black100,
              borderTopLeftRadius: "50%",
              borderTopRightRadius: "50%",
              borderBottomRightRadius: "50%",
              borderBottomLeftRadius: "50%",
              backgroundColor: "transparent",
              borderTopWidth: "1px",
              borderRightWidth: "1px",
              borderBottomWidth: "1px",
              borderLeftWidth: "1px",
              borderTopStyle: "solid",
              borderRightStyle: "solid",
              borderBottomStyle: "solid",
              borderLeftStyle: "solid",
              borderColor: isControl
                ? useDarkTheme
                  ? theme.colors.white
                  : theme.colors.black30
                : theme.colors.blue50,
              fontWeight: "600",
              fontSize: "14px",
              boxSizing: "border-box",
              transitionProperty: "all",
              transitionDuration: "200ms",
              transitionDelay: "200ms",
              transitionTimingFunction: "ease-in-out",
              overflow: "hidden",
              width: radius || "38px",
              height: radius || "38px",
              display: "flex",
              paddingTop: "0px",
              paddingBottom: "0px",
              paddingLeft: "5px",
              paddingRight: "5px",
              alignItems: "center",
              justifyContent: "center",
              position: "relative",
              maskImage: "-webkit-radial-gradient(white, black)",
              ":hover [data-csweb=bg-main]": {
                transform:
                  direction === "left"
                    ? `translateX(-${radius || "38px"})`
                    : `translateX(${radius || "38px"})`,
              },
              ":hover [data-csweb=bg-main] span": {
                transform:
                  direction === "left"
                    ? `translateX(-${(radius * 2) / 3 || "20px"})`
                    : `translateX(${(radius * 2) / 3 || "20px"})`,
              },
              ":hover [data-csweb=bg-hover]": {
                transform: "translateX(0px)",
              },
              ":hover [data-csweb=bg-hover] span": {
                transform: "translateX(0px)",
              },
            },
          },
        }}
        kind={kind}
      >
        <div
          data-csweb="bg-main"
          className={css({
            position: "absolute",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderTopLeftRadius: "50%",
            borderTopRightRadius: "50%",
            borderBottomRightRadius: "50%",
            borderBottomLeftRadius: "50%",
            transform: "translateX(0px)",
            transitionProperty: "all",
            transitionDuration: "400ms",
            transitionDelay: "100ms",
            transitionTimingFunction: "ease-in-out",
            width: radius || "38px",
            height: radius || "38px",
          })}
        >
          <span
            className={css({
              lineHeight: "0.5",
              transitionProperty: "all",
              transitionDuration: "300ms",
              transitionDelay: "0ms",
              transitionTimingFunction: "ease-in-out",
              filter: isControl
                ? useDarkTheme
                  ? "brightness(0) invert(1)"
                  : "brightness(0.4)"
                : color === "white"
                ? "brightness(0) invert(1)"
                : "",
            })}
          >
            {children}
          </span>
        </div>

        <div
          data-csweb="bg-hover"
          className={css({
            position: "absolute",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderTopLeftRadius: "50%",
            borderTopRightRadius: "50%",
            borderBottomRightRadius: "50%",
            borderBottomLeftRadius: "50%",
            transform:
              direction === "left"
                ? `translateX(${radius || "38px"})`
                : `translateX(-${radius || "38px"})`,
            transitionProperty: "all",
            transitionDuration: "400ms",
            transitionDelay: "0ms",
            transitionTimingFunction: "ease-in-out",
            width: radius || "38px",
            height: radius || "38px",
            backgroundColor: color || theme.colors.primary,
          })}
        >
          <span
            className={css({
              filter:
                color === "white"
                  ? "invert(13%) sepia(94%) saturate(6676%) hue-rotate(261deg) brightness(78%) contrast(130%)"
                  : "brightness(0) invert(1)",
              lineHeight: "0.5",
              transitionProperty: "all",
              transitionDuration: "300ms",
              transitionDelay: "200ms",
              transitionTimingFunction: "ease-in-out",
              transform:
                direction === "left"
                  ? `translateX(${(radius * 2) / 3 || "20px"})`
                  : `translateX(-${(radius * 2) / 3 || "20px"})`,
            })}
          >
            {children}
          </span>
        </div>
      </BaseButton>
    );
  } else if (kind === "link") {
    return (
      <BaseButton
        kind={"tertiary"}
        data-csweb="ui-button"
        overrides={{
          BaseButton: {
            style: {
              paddingTop: "0px",
              paddingBottom: "0px",
              paddingLeft: "0px",
              paddingRight: "0px",
              whiteSpace: "nowrap",
              fontWeight: "600",
              alignSelf: "center",
              fontSize: "16.875px",
              height: "35px",
              position: "relative",
              overflow: "hidden",
              maskImage: "-webkit-radial-gradient(white, black)",
              color: theme.colors.contentPrimary,
              "::after": {
                content: "''",
                display: "block",
                position: "absolute",
                bottom: "0",
                left: "-105%",
                right: "0",
                width: "100%",
                height: "2px",
                background:
                  "linear-gradient(135deg, rgba(60,0,220,1) 0%, rgba(0,220,230,1) 100%)",
                transitionProperty: "all",
                transitionDuration: "100ms",
                transitionDelay: "200ms",
                transitionTimingFunction: "ease-in-out",
              },
              ":hover::after": {
                left: "0",
              },
              ":hover": {
                backgroundColor: "transparent",
                cursor: "pointer",
              },
            },
          },
        }}
        {...props}
      >
        <div
          className={css({
            marginTop: "40px",
            transform: "translateY(-33.75px)",
            lineHeight: "2",
            transitionProperty: "all",
            transitionDuration: "300ms",
            transitionTimingFunction: "ease-in-out",
            ":hover": {
              transform: "translateY(0px)",
            },
          })}
        >
          <span className={css({ display: "block" })}>{children}</span>
          <span className={css({ display: "block" })}>{children}</span>
        </div>
      </BaseButton>
    );
  } else
    return (
      <BaseButton
        data-csweb="ui-button"
        overrides={{
          BaseButton: {
            style: {
              whiteSpace: "nowrap",
              color: color || theme.colors.buttonPrimaryText,
              borderTopLeftRadius: theme.borders.radius400,
              borderTopRightRadius: theme.borders.radius400,
              borderBottomRightRadius: theme.borders.radius400,
              borderBottomLeftRadius: theme.borders.radius400,
              backgroundColor: "transparent",
              borderTopWidth: "2px",
              borderRightWidth: "2px",
              borderBottomWidth: "2px",
              borderLeftWidth: "2px",
              borderTopStyle: "solid",
              borderRightStyle: "solid",
              borderBottomStyle: "solid",
              borderLeftStyle: "solid",
              borderTopColor: color || theme.colors.primary,
              borderRightColor: color || theme.colors.primary,
              borderBottomColor: color || theme.colors.primary,
              borderLeftColor: color || theme.colors.primary,
              fontWeight: "600",
              fontSize: "14px",
              paddingTop: theme.sizing.scale200,
              paddingBottom: theme.sizing.scale200,
              boxSizing: "border-box",
              transitionProperty: "all",
              transitionDuration: "200ms",
              transitionDelay: "200ms",
              transitionTimingFunction: "ease-in-out",
              minWidth: `${label.length < 16 ? 16 : label.length + 1}ch`,
              overflow: "hidden",
              maskImage: "-webkit-radial-gradient(white, black)",
              position: "relative",
              "::after": {
                content: "''",
                position: "absolute",
                left: "0px",
                top: "0px",
                right: "0px",
                bottom: "0px",
                background: color
                  ? theme.colors.backgroundLightAccent
                  : "linear-gradient(135deg, rgba(60,0,220,1) 0%, rgba(0,220,230,1) 100%)",
                opacity: "0",
                transitionProperty: "all",
                transitionDuration: "100ms",
                transitionDelay: "200ms",
                transitionTimingFunction: "ease-in-out",
                zIndex: "6",
              },
              ":hover": {
                borderTopWidth: color ? "2px" : "0px",
                borderRightWidth: color ? "2px" : "0px",
                borderBottomWidth: color ? "2px" : "0px",
                borderLeftWidth: color ? "2px" : "0px",
                backgroundColor: "transparent",
              },
              ":hover em": {
                transform: "translateY(0px)",
                color: "white",
              },
              ":hover span": {
                transform: "scale(0)",
              },
              ":hover::after": {
                opacity: "1",
              },
              ...$style,
            },
          },
        }}
        kind={kind}
        {...props}
      >
        <span
          className={css({
            position: "relative",
            transitionProperty: "all",
            transitionDuration: "200ms",
            transitionTimingFunction: "ease-in-out",
            opacity: "1",
            transform: "scale(1)",
          })}
        >
          {children}
        </span>

        <em
          aria-disabled="true"
          className={css({
            position: "absolute",
            zIndex: "10",
            color: "white",
            transitionProperty: "transform",
            transitionDuration: "200ms",
            transitionDelay: "200ms",
            transitionTimingFunction: "ease-in-out",
            transform: "translateY(36px)",
            fontStyle: "normal",
          })}
        >
          {label}
        </em>
      </BaseButton>
    );
};

Button.propTypes = {
  kind: PropTypes.string,
  label: PropTypes.string,
  color: PropTypes.string,
  direction: PropTypes.string,
  radius: PropTypes.string,
  isControl: PropTypes.bool,
  children: PropTypes.node.isRequired,
  $style: PropTypes.object,
};

Button.defaultProps = {
  kind: `primary`,
  direction: `right`,
  children: ``,
  radius: ``,
  label: ``,
  isControl: false,
  $style: {},
};

export default Button;
