import React, { useState } from "react";

import { Textarea as BaseTextarea } from "baseui/textarea";

import { useStyletron } from "baseui";

const Textarea = ({ isRequired, rows = 4, ...props }) => {
  const [showLabel, setShowLabel] = useState(false);

  const [, theme] = useStyletron();

  if (isRequired) {
    props.placeholder = props.placeholder + " *";
  }

  return (
    <BaseTextarea
      {...props}
      onChange={e => {
        setShowLabel(!!e.target.value);
        props.onChange(e);
      }}
      rows={4}
      overrides={{
        Root: {
          props: {
            "data-csweb": "ui-textarea",
          },
          style: {
            backgroundColor: "unset",
            borderLeftWidth: "0px",
            borderTopWidth: "0px",
            borderRightWidth: "0px",
            ...props.$style,
          },
        },
        InputContainer: {
          props: {
            "data-placeholder": props.placeholder,
          },
          style: () => ({
            paddingTop: theme.sizing.scale200,
            backgroundColor: "unset",
            borderLeftWidth: "0px",
            borderTopWidth: "0px",
            borderRightWidth: "0px",
            position: "relative",
            "::after": {
              position: "absolute",
              content: "attr(data-placeholder)",
              ...(showLabel
                ? { top: `-${theme.sizing.scale200}`, opacity: 1 }
                : {
                    top: `${theme.sizing.scale600}`,
                    opacity: 0,
                    pointerEvents: "none",
                  }),
              left: theme.sizing.scale200,
              fontSize: "11.25px",
              color: theme.colors.black50,
              transition: theme.animation.timing300,
            },
          }),
        },
        Input: {
          props: {
            rows,
          },
          style: {
            paddingLeft: theme.sizing.scale200,
            paddingRight: theme.sizing.scale200,
            "::placeholder": {
              color: theme.colors.contentTertiary,
            },
          },
        },
      }}
    />
  );
};

Textarea.propTypes = {
  ...BaseTextarea.propTypes,
};

Textarea.defaultProps = {
  ...BaseTextarea.defaultProps,
};

export default Textarea;
