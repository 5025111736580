import { createTheme, darkThemePrimitives } from "baseui";
import {
  primitives as sharedPrimitives,
  overrides as sharedOverrides,
} from "./shared";

const primitives = {
  ...darkThemePrimitives,
  ...sharedPrimitives,
  primary: "#3C00DC",
  blue50: "#D3DAEE",
  blue0: "#f4f6fb",
  black30: "#b4b5bb",
  black50: "#80838c",
  black100: "#000619",
};

const overrides = {
  ...sharedOverrides,
  colors: {
    contentPrimary: "#fff",
    contentSecondary: "#D3DAEE",
    contentAccent: "#0081EF",
    contentQuaternary: "#aab6d8",
    backgroundPrimary: "#000619",
    backgroundLightAccent: "#3C00DC",
    backgroundSecondary: "#090537",
    backgroundTertiary: "#110f2b",
    buttonPrimaryText: "#ffffff",
    headerNavigationItemColor: "#3C00DC",
    langSwitchActiveText: "#3C00DC",
    langSwitchActiveFill: "#000619",
    langSwitchActiveBorder: "#3C00DC",
    langSwitchText: "#ffffff",
    langSwitchFill: "#3C00DC",
    langSwitchBorder: "#3C00DC",
    leadSideLabelText: "#0081EF",
    servicesCardBorder: "#202536",
    servicesCardGlow: "#0081EF",
    tickBorder: "#b4b5bb",
  },
};

export const darkTheme = createTheme(primitives, overrides);
