import React from "react";
import PropTypes from "prop-types";
import { useStyletron } from "baseui";

const LayoutWrapper = ({ children }) => {
  const [css, theme] = useStyletron();

  return (
    <div
      className={css({
        backgroundColor: theme.colors.backgroundPrimary,
      })}
    >
      {children}
    </div>
  );
};

LayoutWrapper.propTypes = {
  children: PropTypes.node,
};

export default LayoutWrapper;
