import PropTypes from "prop-types";
import React, { useState } from "react";

import { Input as BaseInput } from "baseui/input";

import { useStyletron } from "baseui";

const Input = ({ kind, isRequired, ...props }) => {
  const [showLabel, setShowLabel] = useState(false);

  const [, theme] = useStyletron();

  if (isRequired) {
    props.placeholder = props.placeholder + " *";
  }

  if (kind === "outlined")
    return (
      <BaseInput
        {...props}
        onChange={e => setShowLabel(!!e.target.value)}
        overrides={{
          Root: {
            props: {
              "data-csweb": "ui-input",
            },
            style: {
              backgroundColor: "unset",
              borderTopLeftRadius: theme.borders.radius300,
              borderTopRightRadius: theme.borders.radius300,
              borderBottomRightRadius: theme.borders.radius300,
              borderBottomLeftRadius: theme.borders.radius300,
              borderLeftWidth: "1px",
              borderTopWidth: "1px",
              borderRightWidth: "1px",
              borderBottomWidth: "1px",
              maxWidth: "160px",
              marginLeft: "auto",
              marginRight: "auto",
              ...props.$style,
            },
          },
          InputContainer: {
            props: {
              "data-placeholder": props.placeholder,
            },
            style: () => ({
              backgroundColor: "unset",
              position: "relative",
            }),
          },
          Input: {
            style: {
              fontSize: "18px",
              fontWeight: "700",
              textAlign: "center",
              paddingLeft: theme.sizing.scale200,
              paddingRight: theme.sizing.scale200,
              "::placeholder": {
                color: theme.colors.contentTertiary,
              },
            },
          },
        }}
      />
    );
  else
    return (
      <BaseInput
        {...props}
        onChange={e => setShowLabel(!!e.target.value)}
        overrides={{
          Root: {
            props: {
              "data-csweb": "ui-input",
            },
            style: {
              backgroundColor: "unset",
              borderLeftWidth: "0px",
              borderTopWidth: "0px",
              borderRightWidth: "0px",
              ...props.$style,
            },
          },
          InputContainer: {
            props: {
              "data-placeholder": props.placeholder,
            },
            style: () => ({
              paddingTop: theme.sizing.scale200,
              backgroundColor: "unset",
              position: "relative",
              "::after": {
                position: "absolute",
                content: "attr(data-placeholder)",
                ...(showLabel
                  ? { top: `-${theme.sizing.scale200}`, opacity: 1 }
                  : {
                      top: `${theme.sizing.scale600}`,
                      opacity: 0,
                      pointerEvents: "none",
                    }),
                left: theme.sizing.scale200,
                fontSize: "11.25px",
                color: theme.colors.black50,
                transition: theme.animation.timing300,
              },
            }),
          },
          Input: {
            style: {
              paddingLeft: theme.sizing.scale200,
              paddingRight: theme.sizing.scale200,
              "::placeholder": {
                color: theme.colors.contentTertiary,
              },
            },
          },
        }}
      />
    );
};

Input.propTypes = {
  kind: PropTypes.string,
  ...BaseInput.propTypes,
};

Input.defaultProps = {
  kind: ``,
  ...BaseInput.defaultProps,
};

export default Input;
