import React from "react";

import { Slider as BaseSlider } from "baseui/slider";

import { useStyletron } from "baseui";

const Slider = ({ ...props }) => {
  const [, theme] = useStyletron();

  return (
    <BaseSlider
      {...props}
      onChange={e => {
        props.onChange(e);
      }}
      overrides={{
        Root: {
          props: {
            "data-csweb": "ui-slider",
          },
          style: props.$style,
        },
        InnerTrack: {
          style: {
            height: "10px",
            borderTopLeftRadius: "10px",
            borderTopRightRadius: "10px",
            borderBottomRightRadius: "10px",
            borderBottomLeftRadius: "10px",
            background:
              "linear-gradient(90deg, rgba(60,0,220,1) 0%, rgba(0,220,230,1) 100%)",
            position: "relative",
            ":before": {
              content: "''",
              position: "absolute",
              borderTopLeftRadius: "10px",
              borderBottomLeftRadius: "10px",
              width: `calc(${theme.sizing.scale750} + 3.2ch)`,
              left: "0px",
              top: " 0px",
              bottom: "0px",
              backgroundColor: theme.colors.backgroundTertiary,
            },
          },
        },
        Thumb: {
          style: {
            backgroundColor: theme.colors.white,
            borderTopLeftRadius: "50px",
            borderTopRightRadius: "50px",
            borderBottomRightRadius: "50px",
            borderBottomLeftRadius: "50px",
            boxShadow: "none",
            borderTopWidth: "1px",
            borderRightWidth: "1px",
            borderBottomWidth: "1px",
            borderLeftWidth: "1px",
            borderLeftColor: theme.colors.primary,
            borderTopColor: theme.colors.primary,
            borderRightColor: theme.colors.primary,
            borderBottomColor: theme.colors.primary,
            borderLeftStyle: "solid",
            borderTopStyle: "solid",
            borderRightStyle: "solid",
            borderBottomStyle: "solid",
          },
        },
        InnerThumb: {
          style: {
            backgroundColor: theme.colors.primary,
            borderTopLeftRadius: "50px",
            borderTopRightRadius: "50px",
            borderBottomRightRadius: "50px",
            borderBottomLeftRadius: "50px",
            boxShadow: "none",
            borderTopWidth: "0px",
            borderRightWidth: "0px",
            borderBottomWidth: "0px",
            borderLeftWidth: "0px",
            width: "10px",
            height: "10px",
            transform: "translateY(-50%)",
            top: "50%",
          },
        },
        ThumbValue: {
          component: () => null,
        },
        Tick: {
          style: {
            fontWeight: "500",
          },
        },
        ...props.overrides,
      }}
    />
  );
};

Slider.propTypes = {
  ...BaseSlider.propTypes,
};

Slider.defaultProps = {
  ...BaseSlider.defaultProps,
};

export default Slider;
