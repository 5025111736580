import { createTheme } from "baseui";
import {
  primitives as sharedPrimitives,
  overrides as sharedOverrides,
} from "./shared";

const primitives = {
  ...sharedPrimitives,
  primary: "#3C00DC",
  primary90: "rgba(60, 0, 220, 0.9)",
  blue50: "#D3DAEE",
  blue30: "#e7ebf6",
  blue0: "#f4f6fb",
  black30: "#b4b5bb",
  black50: "#80838c",
  black100: "#000619",
};

const overrides = {
  ...sharedOverrides,
  colors: {
    contentSecondary: "#D3DAEE",
    contentAccent: "#3C00DC",
    contentQuaternary: "#aab6d8",
    backgroundSecondary: "#f4f6fb",
    backgroundTertiary: "#e7ebf6",
    backgroundLightAccent: "#D3DAEE",
    buttonPrimaryText: "#000619",
    buttonSecondaryText: "#000619",
    headerNavigationItemColor: "#000619",
    langSwitchActiveText: "#ffffff",
    langSwitchActiveFill: "#D3DAEE",
    langSwitchActiveBorder: "#D3DAEE",
    langSwitchText: "#ffffff",
    langSwitchFill: "#3C00DC",
    langSwitchBorder: "#3C00DC",
    leadSideLabelText: "#000000",
    servicesCardBorder: "#D3DAEE",
    servicesCardGlow: "#D3DAEE",
    tickBorder: "#b4b5bb",
  },
};

export const lightTheme = createTheme(primitives, overrides);
