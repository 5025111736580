import PropTypes from "prop-types";
import React from "react";

import { useStyletron } from "baseui";
import { Grid as BaseGrid } from "baseui/layout-grid";
import { FlexGrid as BaseFlexGrid } from "baseui/flex-grid";

const Grid = ({ flex, fill, children, ...props }) => {
  const [css, theme] = useStyletron();

  if (flex)
    return (
      <BaseFlexGrid
        flexGridColumnCount={[1, 2, 2, 2]}
        flexGridColumnGap="scale2400"
        flexGridRowGap="scale2400"
        data-csweb="ui-grid flex"
        {...props}
        className={css({
          marginLeft: fill ? `-${theme.sizing.scale700}` : "0px",
          marginRight: fill ? `-${theme.sizing.scale700}` : "0px",
          width: "100%",
          [theme.breakpoints.mediaQueries.medium]: {
            marginLeft: "0px",
            marginRight: "0px",
          },
        })}
      >
        {children}
      </BaseFlexGrid>
    );
  else
    return (
      <BaseGrid
        overrides={{
          Grid: {
            props: {
              "data-csweb": "ui-grid",
            },
            style: props.$style,
          },
        }}
        gridMaxWidth={2240}
        {...props}
      >
        {children}
      </BaseGrid>
    );
};

Grid.propTypes = {
  flex: PropTypes.bool,
  fill: PropTypes.bool,
  children: PropTypes.node.isRequired,
  ...BaseFlexGrid.propTypes,
  ...BaseGrid.propTypes,
};

Grid.defaultProps = {
  flex: false,
  fill: false,
  children: ``,
};

export default Grid;
