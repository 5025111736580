import React from "react";

import { Checkbox as BaseCheckbox } from "baseui/checkbox";

import { useStyletron } from "baseui";

const Checkbox = props => {
  const [, theme] = useStyletron();

  return (
    <BaseCheckbox
      {...props}
      overrides={{
        Checkmark: {
          style: ({ $checked, $error }) => ({
            backgroundColor: $checked ? theme.colors.tickBorder : "unset",
            borderColor: $error
              ? theme.colors.negative
              : theme.colors.tickBorder,
            borderTopWidth: "1px",
            borderLeftWidth: "1px",
            borderRightWidth: "1px",
            borderBottomWidth: "1px",
            backgroundSize: "12px auto",
          }),
        },
        Label: {
          style: {
            fontSize: "13.5px",
            fontWeight: "400",
            color: theme.colors.black50,
          },
        },
      }}
    >
      {props.children}
    </BaseCheckbox>
  );
};

Checkbox.propTypes = {
  ...BaseCheckbox.propTypes,
};

Checkbox.defaultProps = {
  ...BaseCheckbox.defaultProps,
};

export default Checkbox;
