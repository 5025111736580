import React from "react";
import PropTypes from "prop-types";

import { BaseProvider, useStyletron } from "baseui";
import { navigate } from "gatsby";

import { Header } from "../header";
import { Footer } from "../footer";

import "./layout-root.css";
import "./../../../static/fonts/fonts.css";
import LayoutWrapper from "./layout-wrapper";

import { lightTheme, darkTheme } from "./../../themes";
import { useTheme } from "../../contexts/theme";

import VisibilitySensor from "react-visibility-sensor";
import { Spring } from "react-spring/renderprops";
import { StyledLink } from "baseui/link";
import { HeadingMedium } from "baseui/typography";
import { Block } from "baseui/block";
import { Cell, Grid } from "../ui";

import chevronRight from "../../assets/images/chevron-right.svg";
import chevronRightDark from "../../assets/images/chevron-right-dark.svg";
import atendeLogo from "../../assets/images/atende.svg";
import atendeWhiteLogo from "../../assets/images/atende-white.svg";

const Layout = ({ children, header = {}, footer = {}, title }) => {
  const [css, theme] = useStyletron();
  const { useDarkTheme } = useTheme();

  return (
    <main role="main" data-csweb="app">
      <BaseProvider
        theme={useDarkTheme ? darkTheme : lightTheme}
        overrides={{
          AppContainer: {
            props: {
              "data-csweb": "app-main",
            },
          },
          LayersContainer: {
            props: {
              "data-csweb": "app-layers",
            },
          },
        }}
      >
        <LayoutWrapper>
          {header && (
            <Spring from={{ top: -100 }} to={{ top: 0 }}>
              {({ top }) => <Header $style={{ top: `${top}px` }} {...header} />}
            </Spring>
          )}

          {React.Children.map(children, child =>
            React.Children.map(child.props.children, (child, index) => {
              if (
                (title === "Home" && index === 6) ||
                (title === "AboutUs" && index === 1)
              )
                return child;
              else
                return (
                  <VisibilitySensor
                    key={index}
                    partialVisibility
                    offset={{
                      top: -10000,
                      bottom:
                        typeof window !== "undefined" && window
                          ? window.innerHeight / 8
                          : 0,
                    }}
                  >
                    {({ isVisible }) => (
                      <Spring
                        config={{ tension: 120, friction: 14, duration: 600 }}
                        from={{ opacity: 0 }}
                        to={{ opacity: isVisible ? 1 : 0 }}
                      >
                        {style => {
                          return (
                            <div
                              data-csweb="spring-wrapper"
                              className={css(style)}
                            >
                              {child}
                            </div>
                          );
                        }}
                      </Spring>
                    )}
                  </VisibilitySensor>
                );
            })
          )}

          {footer && <Footer {...footer} />}

          <Grid
            $style={{
              position: "fixed",
              bottom: "20px",
              left: "0px",
              right: "0px",
              zIndex: "1000",
            }}
          >
            <Cell span={[4, 8, 8]} skip={[0, 0, 2]}>
              <Block
                $as="a"
                href="/polaczenie-z-atende"
                overrides={{
                  Block: {
                    style: {
                      textDecoration: "none",
                      borderTopWidth: "1px",
                      borderRightWidth: "1px",
                      borderBottomWidth: "1px",
                      borderLeftWidth: "1px",
                      borderTopStyle: "solid",
                      borderRightStyle: "solid",
                      borderBottomStyle: "solid",
                      borderLeftStyle: "solid",
                      zIndex: "4",
                      borderTopColor: useDarkTheme
                        ? darkTheme.colors.servicesCardBorder
                        : lightTheme.colors.servicesCardBorder,
                      borderRightColor: useDarkTheme
                        ? darkTheme.colors.servicesCardBorder
                        : lightTheme.colors.servicesCardBorder,
                      borderBottomColor: useDarkTheme
                        ? darkTheme.colors.servicesCardBorder
                        : lightTheme.colors.servicesCardBorder,
                      borderLeftColor: useDarkTheme
                        ? darkTheme.colors.servicesCardBorder
                        : lightTheme.colors.servicesCardBorder,
                      backgroundColor: useDarkTheme
                        ? darkTheme.colors.backgroundPrimary
                        : lightTheme.colors.backgroundPrimary,
                      borderRadius: "16px",
                      height: "100%",
                      display: "flex",
                      flexWrap: "wrap",
                      justifyContent: "space-between",
                      alignItems: "center",
                      padding: theme.sizing.scale1000,
                      boxSizing: "border-box",
                      transitionProperty: "all",
                      transitionDuration: "200ms",
                      transitionTimingFunction: "ease-in-out",
                      boxShadow: `0px 0px 20px 0px ${
                        useDarkTheme
                          ? darkTheme.colors.backgroundTertiary
                          : lightTheme.colors.backgroundTertiary
                      }`,
                      ":hover": {
                        cursor: "pointer",
                      },
                      ":hover [data-csweb=service-learn-more]": {
                        paddingRight: "20px",
                      },
                      ":hover [data-csweb=service-learn-more]::after": {
                        width: "100%",
                      },
                      ":hover [data-csweb=service-learn-more] img": {
                        opacity: "1",
                      },
                      ":hover [data-csweb=typo-caption]": {
                        color: theme.colors.contentPrimary,
                      },
                      [theme.breakpoints.mediaQueries.small]: {
                        padding: theme.sizing.scale600,
                      },
                    },
                  },
                }}
                onClick={e => {
                  e.preventDefault();
                  navigate("/polaczenie-z-atende");
                }}
              >
                <img
                  alt="Atende"
                  src={useDarkTheme ? atendeWhiteLogo : atendeLogo}
                  height="20px"
                  className={css({
                    marginRight: "32px",
                    [theme.breakpoints.mediaQueries.small]: {
                      height: "16px",
                      marginTop: theme.sizing.scale400,
                    },
                  })}
                />

                <HeadingMedium
                  $style={{
                    transitionProperty: "all",
                    transitionDuration: "200ms",
                    transitionTimingFunction: "ease-in-out",
                    margin: 0,
                  }}
                >
                  Połączenie Atende S.A. z Codeshine sp. z o.o.
                </HeadingMedium>

                <div
                  className={css({
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    marginLeft: "auto",
                  })}
                >
                  <StyledLink
                    data-csweb="service-learn-more"
                    $as="div"
                    $style={{
                      position: "relative",
                      lineHeight: "2",
                      fontWeight: "700",
                      fontSize: "15px",
                      paddingRight: "0px",
                      textDecoration: "none",
                      transitionProperty: "all",
                      transitionDuration: "400ms",
                      transitionTimingFunction: "ease-in-out",
                      color: useDarkTheme
                        ? darkTheme.colors.contentAccent
                        : lightTheme.colors.contentAccent,
                      "::before": {
                        content: "''",
                        position: "absolute",
                        bottom: "0px",
                        left: "0px",
                        right: "0px",
                        height: "1px",
                        background: theme.colors.blue50,
                      },
                      "::after": {
                        content: "''",
                        position: "absolute",
                        bottom: "0px",
                        right: "0px",
                        height: "1px",
                        width: "0%",
                        background: useDarkTheme
                          ? darkTheme.colors.contentAccent
                          : lightTheme.colors.contentAccent,
                        transitionProperty: "all",
                        transitionDuration: "300ms",
                        transitionTimingFunction: "ease-in-out",
                      },
                      ":hover": {
                        color: useDarkTheme
                          ? darkTheme.colors.contentAccent
                          : lightTheme.colors.contentAccent,
                      },
                    }}
                  >
                    czytaj dalej
                    <img
                      alt="More"
                      src={useDarkTheme ? chevronRightDark : chevronRight}
                      height="14px"
                      className={css({
                        opacity: "0",
                        position: "absolute",
                        right: "0px",
                        transitionProperty: "all",
                        transitionDuration: "200ms",
                        transitionDelay: "100ms",
                        transitionTimingFunction: "ease-in-out",
                        top: "50%",
                        transform: "translateY(-50%)",
                      })}
                    />
                  </StyledLink>
                </div>
              </Block>
            </Cell>
          </Grid>
        </LayoutWrapper>
      </BaseProvider>
    </main>
  );
};

Layout.propTypes = {
  children: PropTypes.node,
  header: Header.propTypes,
  footer: Footer.propTypes,
  title: PropTypes.string,
};

export default Layout;
