import Header from "./header.yaml";
import Footer from "./footer.yaml";
import Home from "./home.yaml";
import AboutUs from "./about-us.yaml";
import Contact from "./contact.yaml";
import Services from "./services.yaml";
import CaseStudies from "./case-studies.yaml";
import Careers from "./careers.yaml";
import EstimateForm from "./estimate-form.yaml";
import Errors from "./errors.yaml";
import PrivacyPolicy from "./privacy-policy.yaml";
import Shared from "./shared.yaml";
import Jobs from "./jobs.yaml";
import MergeNotice from "./merge-notice.yaml";

export default {
  Header,
  Footer,
  Home,
  AboutUs,
  Contact,
  Services,
  CaseStudies,
  Careers,
  EstimateForm,
  Errors,
  PrivacyPolicy,
  Shared,
  MergeNotice,
  Jobs,
};
