import LinkedIn from "./social-linkedin.svg";
import Facebook from "./social-facebook.svg";
import Twitter from "./social-twitter.svg";
import GitHub from "./social-github.svg";
import Clutch from "./social-clutch.svg";
import Email from "./social-email.svg";
import ProductHunt from "./social-producthunt.svg";

export default {
  LinkedIn,
  Facebook,
  Twitter,
  GitHub,
  Clutch,
  Email,
  ProductHunt,
};
